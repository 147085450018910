<template>
  <mex-sheet rounded color="foreground">
    <v-row>
      <v-col cols="12">
        <codemirror ref="cm" v-model="content" :options="cmOptions" />
      </v-col>
    </v-row>
    <v-row justify="end" class="ma-0">
      <v-col cols="auto" class="pt-0 pb-0">
        <mex-p class="mb-0" :content="`MODE: ${language}`" fontSize="caption" />
      </v-col>
    </v-row>
  </mex-sheet>
</template>

<script>
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';

// language
import 'codemirror/mode/sql/sql';
import 'codemirror/mode/javascript/javascript';

// theme css
import 'codemirror/theme/solarized.css';
import 'codemirror/theme/monokai.css';

export default {
  name: 'CodeEditor',
  components: {
    codemirror,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
      default: '',
    },
    language: {
      type: String,
      default: 'text/x-mysql',
    },
  },
  computed: {
    cm() {
      return this.$refs.cm.codemirror;
    },
  },
  data() {
    return {
      languages: [
        {
          text: 'js',
          value: 'text/javascript',
        },
        {
          text: 'SQL',
          value: 'text/x-mysql',
        },
      ],
      selectedLanguage: null,
      themes: [
        {
          text: 'solarized light',
          value: 'solarized light',
        },
        {
          text: 'monokai dark',
          value: 'monokai',
        },
      ],
      selectedTheme: null,
      content: '',
      cmOptions: {
        //tabSize: 4,
        styleActiveLine: true,
        line: true,
        lineNumbers: false,
        mode: 'text/x-mysql',
        theme: 'monokai',
      },
    };
  },
  mounted() {
    this.cmOptions.mode = this.language;
    this.content = this.code;
  },
  watch: {
    code(newVal) {
      if (newVal !== this.content) {
        this.content = newVal;
      }
    },
    content(newVal) {
      this.$emit('code-updated', newVal);
    },
  },
};
</script>

<style scoped></style>
